@media print {
  /* @page {
    size: A4;
    margin-top: 16mm;
    margin-bottom: 280px;
  } */

  div.content{
    margin-top: 370px;
    margin-bottom: 300px;
  }

  div.tablecontent{
    margin-top: 520px;
  }
  div.tablesignature{
    margin-top: 400px;
  }

  div.page-header {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 480px;
    /* font-size: 15px; */
    /* opacity: 0.5; */
    page-break-after: always;
  }

  div.page-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 280px;
    /* font-size: 15px; */
    /* opacity: 0.5; */
    page-break-after: always;
  }
}