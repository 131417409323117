@media print {
  div.content{
    margin-top: 370px;
    margin-bottom: 300px;
  }

  div.tablecontent{
    margin-top: 520px;
  }
  div.tablesignature{
    margin-top: 4000px;
  }

  div.page-header {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 480px;
    margin-left: 30px;
    /* font-size: 15px; */
    /* opacity: 0.5; */
    page-break-after: always;
  }

  div.page-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 250px;
    /* font-size: 15px; */
    /* opacity: 0.5; */
    page-break-after: always;
  }
  div.page-signature {
    position: fixed;
    z-index: 9;
    bottom: 0;
    width: 100%;
    height: 211px;
    /* font-size: 15px; */
    opacity: 0.5;
    page-break-after: always;
  }
  div.page-break{
    page-break-after: always;
    margin-top: 1rem;
    display: block;
  }
}