.pccDashContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 100px 50px auto;
}

.pccDashContainer > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pccDashContainer > div > h3 {
  font-size: 1rem;
  margin: 30px 0px 20px 0px;
  color: rgba(66, 58, 58, 0.87);
}

.cardsAppointment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  align-items: center;
  justify-content: space-between;
}

#lims .cardsAppointment > div {
  background: transparent linear-gradient(90deg, #246ea3, #1C4E80) 0 0 no-repeat
    padding-box;

  box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 20%;
  margin: 0px 0px 30px 0px;

  display: flex;
  align-items: right;
  flex-direction: column;

  padding: 15px;
}

.cardsAppointment > div > h3 {
  text-align: center;
  font-size: 0.7vw;
  color: white;
}

.cardsAppointment > div > h2 {
  text-align: center;
  font-size: 2vw;
  color: white;
}

@media screen and (max-width: 720px) {
  .pccDashContainer {
    width: 100%;
    margin: auto;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }
  .cardsAppointment > div {
    width: 30%;
  }

  .cardsAppointment > div > h3 {
    text-align: center;
    font-size: 2vw;
    color: white;
  }

  .cardsAppointment > div > h2 {
    text-align: center;
    font-size: 5vw;
    color: white;
  }

  .pccDashContainer > div > h3 {
    font-size: 5vw;
    margin: 30px 0px 20px 0px;
    color: rgba(66, 58, 58, 0.87);
  }
}

/* table admin  */

.admin__dashboard__container {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  min-width: 800px;
}

.admin__dashboard__left {
  display: flex;
  background-color: #924a90;
  height: 150px;
  width: 190px;
  align-items: center;
}

.admin__dashboard__left > p {
  transform: rotate(-90deg);
  width: max-content;
  font-size: 17px;
  font-weight: 600;
  transform-origin: top left;
  align-self: flex-end;
  color: white;
  margin-bottom: -10px;
  margin-left: 10px;
}
.admin__dashboard__left > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 112px;
  justify-content: space-between;
  color: white;
  margin-left: -70px;
  margin-top: 22px;
}

.admin__dashboard__left > div > p {
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin__dashboard__upper {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  flex-direction: column;
  height: 100%;
}
.admin__dashboard__upper > div:nth-child(1) {
  color: white;
  height: 50px;
  background-color: #924a90;
}

.admin__dashboard__upper > div {
  display: flex;
  align-items: center;
  width: 100%;
  color: black;
  justify-content: space-around;
  height: 50px;
}
.admin__dashboard__upper > div > p {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
